@tailwind base;
@tailwind components;
@tailwind utilities;

.file-types {
  /* This hides file names from the drag and drop library button */
  display: none;
}

label.sc-aXZVg {
  /* This modifies the properties of the drag and drop library button */
  /* border: none !important; */
  min-width: 0;
}

.card-header {
  font-weight: bold;
  color: #072a48;
}

.standard-form-input {
  display: block;
  padding: 5px;
  border: solid grey 1px;
  margin-bottom: 10px;
}

.submit-button {
  background-color: #072a48;
  color: white;
  width: 100%;
  margin-top: 10px;
  padding: 7px 7px;
  border-radius: 5px;
}

.submit-button:hover {
  background-color: #116582;
  color: white;
  width: 100%;
  margin-top: 10px;
  padding: 7px 7px;
  border-radius: 5px;
}

.bottom-navbar {
  display: none; /* Initially hide the navbar */
}

@media only screen and (max-width: 600px) {
  .bottom-navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 999;
    background-color: white;
  }

  .bottom-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #072a48;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
  }

  .nav-button {
    flex: 1; /* Equal width for each button */
    color: white;
    border: none;
    background-color: transparent;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
  }
}

.datePicker {
  width: 100%;
}

.data-matrix-table {
  border-collapse: collapse;
  width: 100%;
}

.data-matrix-table th,
.data-matrix-table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;
}

.data-matrix-table th {
  background-color: #f2f2f2;
}

.blank-cell {
  background-color: #333333;
  color: #ffffff;
}

.larger-checkbox .MuiSvgIcon-root {
}

.larger-checkbox .Mui-checked .MuiSvgIcon-root {
  color: #116582;
}
