.submit-button {
    background-color: #072a48;
    color: white;
    width: 100%;
    margin-top: 10px;
    padding: 7px 7px;
    border-radius: 5px;
  }
  
  .submit-button:hover {
    background-color: #116582;
    color: white;
    width: 100%;
    margin-top: 10px;
    padding: 7px 7px;
    border-radius: 5px;
  }
  
  .standard-form-input {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    padding-right: 3px;
    width: 100%;
    border-radius: 5px;
  }
  
  .panel-title {
    font-weight: 600;
    font-size: larger;
    color: #072a48;
    padding-top: 10px;
  }
  
  .header-text {
    color: #072a48;
    font-weight: bold;
    font-size: 20px;
  }
  
  .cancel-text {
    color: darkred;
    margin-bottom: 3px;
  }
  
  .cancel-text:hover {
    color: red;
    margin-bottom: 3px;
  }
  