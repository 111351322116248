.realBody {
  background-color: #072a48;
  padding: 20px;
}

.driverMap {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.line {
  width: 4px;
  background-color: cyan;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 1000;
}

.branch {
  display: flex;
  align-items: center;
  margin: 20px 0;
  position: relative;
}

.branchLeft {
  justify-content: flex-end;
  margin-left: 434px;
}

.branchRight {
  justify-content: flex-start;
  margin-right: 434px;
}

.hexagon {
  width: 120px;
  padding: 10px;
  margin: 0 10px;
  height: auto;
  background-color: #072a48;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  z-index: 1;
  text-align: center;
}

.realTable {
  width: 200px;
  border-collapse: collapse;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1;
}

.realTable th,
.realTable td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.realTable th {
  background-color: #f4f4f4;
}

.connector {
  width: 2px;
  background-color: #333;
  height: 200px;
  position: absolute;
}

.orange {
  background-color: orange;
  border: solid 2px orange;
}

.orangeBorder {
  border: solid 4px orange;
}

.yellow {
  background-color: #ffdb58;
  border: solid 2px #ffdb58;
}

.yellowBorder {
  border: solid 4px #ffdb58;
}

.limegreen {
  background-color: limegreen;
  border: solid 2px limegreen;
}

.limegreenBorder {
  border: solid 4px limegreen;
}

.purple {
  background-color: rgb(130, 0, 130);
  border: solid 2px rgb(158, 1, 158);
}

.purpleBorder {
  border: solid 4px rgb(158, 1, 158);
}

.red {
  background-color: red;
  border: solid 2px red;
}

.redBorder {
  border: solid 4px red;
}

.grey {
  background-color: grey;
  border: solid 2px grey;
}

.greyBorder {
  border: solid 4px grey;
}

.pink {
  background-color: magenta;
  border: solid 2px magenta;
}

.pinkBorder {
  border: solid 4px magenta;
}

.rosyRed {
  background-color: #d00909;
  border: solid 2px #d00909;
}

.rosyRedBorder {
  border: solid 4px #d00909;
}

.branchLeft .connector {
  right: 285px;
  transform: rotate(90deg);
}

.branchRight .connector {
  left: 290px;
  transform: rotate(90deg);
}

.mainHexagon {
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: cyan 4px solid;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hexagonImg {
  display: block;
  margin: 0 auto 10px;
  width: 50px;
  height: auto;
}

.textTitle1 {
  font-size: 16px;
  text-align: center;
  max-width: 185px;
  margin: 0 auto;
}

.textTitle2 {
  font-size: 15px;
}

.textTitle3 {
  font-size: 14px;
}

.textTitle4 {
  font-size: 14px;
}

.table {
  margin: 0 0 10px;
}

.riskFactorInfo {
  border: solid 3px #d00909;
  border-collapse: collapse;
}

.riskFactorInfo th,
.riskFactorInfo td {
  border: solid 3px #d00909;
  padding: 8px;
}

.riskFactorInfo th {
  border: solid 3px #d00909;
  background-color: #f1a1a1;
}

.riskFactorInfo tr:nth-child(odd) {
  background-color: #f7c0c0;
}

.riskFactorInfo tr:nth-child(even) {
  background-color: #f4a4a4;
}

.basicInfo {
  border: solid 3px orange;
  border-collapse: collapse;
}

.basicInfo th,
.basicInfo td {
  border: 3px solid orange;
  padding: 8px;
}

.basicInfo th {
  background-color: #ffb347;
  color: #333;
  text-align: left;
  padding: 8px;
}

.basicInfo td {
  border: 3px solid orange;
  padding: 8px;
  text-align: left;
}

.basicInfo tr:nth-child(even) td {
  background-color: #ffe5b4;
  color: #333;
}

.basicInfo tr:nth-child(odd) td {
  background-color: #ffd699;
  color: #333;
}

.scoreInfo {
  border: solid 3px #ffdb58;
  border-collapse: collapse;
}

.scoreInfo th,
.scoreInfo td {
  border: 3px solid #ffdb58;
  padding: 8px;
}

.scoreInfo th {
  background-color: #ffd700;
  color: #333;
  padding: 8px;
}

.scoreInfo td {
  border: 3px solid #ffdb58;
  padding: 8px;
}

.scoreInfo tr:nth-child(even) td {
  background-color: #fff5cc;
  color: #333;
}

.scoreInfo tr:nth-child(odd) td {
  background-color: #ffecb3;
  color: #333;
}

.crashesInfo {
  border: solid 3px magenta;
  border-collapse: collapse;
}

.crashesInfo th,
.crashesInfo td {
  border: solid 3px magenta;
  padding: 8px;
}

.crashesInfo th {
  border: solid 3px magenta;
  background-color: rgb(232, 146, 232);
}

.crashesInfo tr:nth-child(odd) {
  background-color: rgb(232, 162, 232);
}

.crashesInfo tr:nth-child(even) {
  background-color: rgb(203, 112, 203);
}

.sctInfo {
  border: solid 3px grey;
  border-collapse: collapse;
}

.sctInfo th,
.sctInfo td {
  border: 3px solid grey;
  padding: 8px;
}

.sctInfo th {
  background-color: #f4f4f4;
  color: #333;
  text-align: left;
  padding: 8px;
}

.sctInfo td {
  border: 3px solid grey;
  padding: 8px;
  text-align: left;
}

.sctInfo tr:nth-child(even) td {
  background-color: rgb(232, 230, 230);
  color: #333;
}

.sctInfo tr:nth-child(odd) td {
  background-color: #f4f4f4;
  color: #333;
}

.trainings {
  border: solid 3px limegreen;
  background-color: limegreen;
  z-index: 3000;
}

.trainingsInfo {
  border: solid 3px limegreen;
  border-collapse: collapse;
}

.trainingsInfo th,
.trainingsInfo td {
  border: 3px solid limegreen;
  padding: 8px;
}

.trainingsInfo th {
  background-color: #00ff00;
  color: #333;
  text-align: left;
  padding: 8px;
}

.trainingsInfo td {
  border: 3px solid limegreen;
  padding: 8px;
  text-align: left;
}

.trainingsInfo tr:nth-child(even) td {
  background-color: #ccffcc;
  color: #333;
}

.trainingsInfo tr:nth-child(odd) td {
  background-color: #b3ffb3;
  color: #333;
}

.policy {
  border: solid 3px rgb(158, 1, 158);
  background-color: rgb(158, 1, 158);
  z-index: 0;
}

.policyInfo {
  border: solid 3px rgb(158, 1, 158);
  border-collapse: collapse;
}

.policyInfo th,
.policyInfo td {
  border: 3px solid rgb(158, 1, 158);
  padding: 8px;
}

.policyInfo th {
  background-color: #f4f4f4;
  color: #333;
  text-align: left;
  padding: 8px;
}

.policyInfo td {
  border: 3px solid rgb(158, 1, 158);
  padding: 8px;
  text-align: left;
}

.policyInfo tr:nth-child(even) td {
  background-color: #f4f4f4;
  color: #333;
}

.policyInfo tr:nth-child(odd) td {
  background-color: #f4f4f4;
  color: #333;
}

.inspections {
  border: solid 3px red;
  background-color: red;
  z-index: 3000;
}

.inspectionsInfo {
  border: solid 3px grey;
  border-collapse: collapse;
  margin-bottom: 7px;
  margin-top: 7px;
}

.inspectionsInfo th,
.inspectionsInfo td {
  border: 3px solid grey;
  padding: 8px;
}

.inspectionsInfo th {
  background-color: #f4f4f4;
  color: #333;
  text-align: left;
  padding: 8px;
}

.inspectionsInfo td {
  border: 3px solid grey;
  padding: 8px;
  text-align: left;
}

.inspectionsInfo tr:nth-child(even) td {
  background-color: rgb(232, 230, 230);
  color: #333;
}

.inspectionsInfo tr:nth-child(odd) td {
  background-color: #f4f4f4;
  color: #333;
}